
import { ref, reactive, defineComponent, Ref, watch, computed, onMounted } from 'vue';
import type { FormInstance } from 'element-plus';
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter } from 'vue-router';
import { Delete, Plus } from '@element-plus/icons-vue';
import Api from '@/services/Api';
import useAlert from '@/composables/Alert'

interface JiraInterface{
  key: string | number | null,
  tipo: string | null,
}
interface Projeto {
  key: string;
  name: string;
}

export default defineComponent({
  name: "FormCreateTickets",
    components: {Title,Button},
  setup() {
    const router = useRouter();
    const { showTimeAlert } = useAlert();
    const arrayStatus: Ref<any> = ref([]);
    const arrayCategory: Ref<any> = ref([]);
    const selectedStatus: Ref<any> = ref([]);
    const selectedCategory: Ref<number | null> = ref(null);
    const jiraOptions: Ref<JiraInterface> = ref({key: null, tipo: null})
    const formRef = ref<FormInstance>()
    const dynamicForm = ref<any>({
        titulo: 'Insira o nome do formulário',
        campos: []
    });
    const issues: Ref<any> = ref(['Task', 'Story', 'Bug', 'Tarefa'])
    const projetos: Ref<Projeto[]> = ref([]);
    const storedProjects = localStorage.getItem('projectsName');
    const nameProject: Ref<string | null> = ref(storedProjects ? JSON.parse(storedProjects)[0] : null);


    const removeField = (index: number) => {
      dynamicForm.value.campos.splice(index, 1)
    }

    const fieldTypes: Record<string, string> = {
      input: 'Insira o nome do campo',
      textarea: 'Insira o nome do campo',
      date: 'Data',
      select: 'Status'
    };

    const addField = (type: string, value: string | null = null) => {
      const label = fieldTypes[type] || 'Insira o nome do campo';
      dynamicForm.value.campos.push({
        label,
        type,
        value,
      })
    }

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      if(selectedCategory.value === null || !dynamicForm.value.campos.length) return showTimeAlert("Categoria e campos sao obrigatórios", "error")
      formEl.validate(async (valid) => {
        if (valid) {
          try {
              const res = await Api.post('createForm', {
                id: selectedCategory.value,
                form: JSON.stringify({
                  dynamicForm: dynamicForm.value,
                  jiraOptions: jiraOptions.value,
                }),
              });

              if (!res.error) {
                showTimeAlert("Formulário criado com sucesso", "success");
              }
          } catch (error) {
            showTimeAlert("Erro ao criar formulário", "error");
          }
        } else {
          showTimeAlert("Erro na validação do formulário", "error");
        }
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }

    const formattedDynamicForm = computed(() => {
      return JSON.stringify(dynamicForm.value, null, 2);
    });

    async function getStatus() {
      const { data } = await Api.get("status")
      arrayStatus.value = data
    }

    async function getCategories(){
      const { data } = await  Api.get('categories');
      arrayCategory.value = data
    }

    const insertOptions = (i: number) => {
      dynamicForm.value.campos[i].options = []
      dynamicForm.value.campos[i].options.push(...selectedStatus.value);
    }

    async function getProjects() {
      try {
        const { data } = await Api.get("getAllProjects")
        projetos.value = data

      } catch (error) {
        console.log(error);
      }
    }

    async function findForm(){
      const {data} = await Api.get('getForm', {id: selectedCategory.value})
      if(!data) {
        showTimeAlert("Categoria ainda nao possui form cadastrado", "error")
        dynamicForm.value.titulo = 'Insira o nome do formulário'
        dynamicForm.value.campos = []
        jiraOptions.value = {key: null, tipo: null}
        return 
      }
      if(JSON.parse(data).dynamicForm){
        dynamicForm.value.titulo = JSON.parse(data).dynamicForm.titulo;
        dynamicForm.value.campos = JSON.parse(data).dynamicForm.campos;
        jiraOptions.value = JSON.parse(data).jiraOptions;
      } else {
        dynamicForm.value.titulo = JSON.parse(data).titulo;
        dynamicForm.value.campos = JSON.parse(data).campos;
        jiraOptions.value = {key: null, tipo: null}
      }
    }

    onMounted(()=> {getStatus(), getCategories(), getProjects()})

    return {
      formRef,
      dynamicForm,
      removeField,
      addField,
      submitForm,
      resetForm,
      formattedDynamicForm,
      router,
      arrayStatus,
      Delete,
      Plus,
      insertOptions,
      selectedStatus,
      arrayCategory,
      selectedCategory,
      jiraOptions,
      issues,
      projetos,
      findForm,
      nameProject
    }
  },
});
